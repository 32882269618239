import './App.css';
import Menu from './Menu'
import Home from './Home'
import SanPhamXemNhieu from './SanPhamXemNhieu';
import GioiThieu from './GioiThieu';
import ChiTiet from './ChiTiet'
import SPTrongLoai from './SPTrongLoai'
import TimKiem from './TimKiem';
import NotFound from './NotFound'
import ShowCart from './ShowCart';
import ThanhToan from './ThanhToan';
import { BrowserRouter,Routes, Route } from "react-router-dom";
var sotin=10 ;
function App() {
  return (
    <BrowserRouter basename="/">
    <div className="container">
        <header> </header>
        <nav> <Menu/> </nav>
        <main className='d-flex'> 
            <article className='col-md-9'> 
            <Routes>
              <Route path="/" exact element={<Home/>} />
              <Route path="/gioithieu" element={<GioiThieu/>} />
              <Route path="/sp/:id" element={<ChiTiet/>} />
              <Route path="/loai/:id_loai" element={<SPTrongLoai/>} />
              <Route path="/timkiem/" element={<TimKiem/>} />
              <Route path="/showcart/" element={<ShowCart/>} />
              <Route path="/thanhtoan/" element={<ThanhToan/>} />
              <Route element={<NotFound/>}/>
            </Routes>

            </article>
            <aside className='col-md-3'> 
            <SanPhamXemNhieu sotin={sotin}/> 
            </aside>
        </main>
        <footer><p>Họ tên sinh viên: xxx</p> </footer>
      </div>
      </BrowserRouter>
  );
}
export default App;
