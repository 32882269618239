import React from "react";

import { Link } from "react-router-dom";
class Menu extends React.Component {
constructor(){
    super();
    this.state = {listloai:[]}
}
componentDidMount(){
    fetch(`${process.env.REACT_APP_API_URL}/loai`)
    .then (res => res.json() ).then ( data  => this.setState({listloai:data}))
}
render(){ return (
    <ul><li> <Link to={"/"}> Trang chủ </Link>   </li>  
    { this.state.listloai.map( (loai, i) => 
        <li key={i}> 
        <Link to= {"/loai/"+ loai.id} > {loai.ten_loai} </Link> 
        </li>  
    )//map
    }
    <li> <Link to= "/gioithieu" > Giới thiệu </Link> </li>
    <li> <Link to= "/showcart" > Giỏ hàng </Link> </li>
    </ul>)
} //render
};
export default Menu;
