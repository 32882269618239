//import { listsp } from "./data.js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function SanPhamXemNhieu(props) {
    const [listsp, ganlistsp] = useState([])
    useEffect( ()=> { 
        fetch(`${process.env.REACT_APP_API_URL}/spxemnhieu/10`)
        .then( res => res.json()).then( data =>  ganlistsp(data))
    } , []); 
    let jsxcode = 
        <div id="spxn">
        {listsp.slice(0,props.sotin).map( (sp, i)=>
        <div className="sp" key={i}> 
        <Link to={ "/sp/" + sp.id } > {sp['ten_sp']} </Link>
        </div> )}
        </div>
    return (jsxcode );
}
export default SanPhamXemNhieu;

