import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { xoaSP, soaGH, suaSL } from "./cartSlice";
function ShowCart(props) {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.listSP);
  return (
    <div id="giohang">
      <h2>Giỏ hàng của bạn</h2>
      {cart.map ((sp, index) => { return (
        <div key={index}>
          <span>{sp.ten_sp}</span>
          <input type="number" defaultValue={sp.so_luong}  
          onClick={ e => dispatch( suaSL([sp.id, e.target.value] ) ) }
          />
          <span>{Number(sp.gia).toLocaleString("vi")} VNĐ</span>
          <span>{Number(sp.gia*sp.so_luong).toLocaleString("vi")} VNĐ</span>
          <span> <a href="#/" onClick={ ()=>dispatch(xoaSP(sp.id)) } >Xóa</a> </span>
        </div>
      )})}
      <Link to='/thanhtoan'>Thanh toán</Link>
    </div>
  );
}
export default ShowCart;
